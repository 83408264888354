<template>
  <div>
    <!-- 视频播放 -->
    <div class="videolive">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
        @playing="onPlay"
      >
      </video-player>
    </div>
    <div class="bottomlive">
      <el-button type="primary" @click="playback('1')">上传本地视频</el-button>
      <el-button type="primary" @click="playback('2')"
        >保存外部回放链接</el-button
      >
      <el-button type="primary" @click="editlive">返回编辑页面</el-button>
    </div>
    <div>
      <div v-if="liveupload == 1">
        <div class="videoliveweb">上传视频</div>
        <el-upload
          :http-request="liveuploadone"
          class="listtoavatar"
          :show-file-list="false"
          :on-success="liveuploadoneAvatarSuccess"
          :before-upload="beforliveuploadone"
          :on-change="liveuploadonelisttolive"
          :auto-upload="false"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
          <div class="fontliveupload" v-if="dialogVisible">
            <div>{{ timelist }}</div>
            <div>请勿动此页面</div>
          </div>
        </el-upload>
        <div class="videolivewebbottom">
          <div>
            <el-button type="primary" size="small" @click="preview"
              >预览视频</el-button
            >
          </div>
          <div>
            <el-button type="primary" size="small" @click="netuploadplayer"
              >使用视频</el-button
            >
          </div>
        </div>
      </div>
      <div v-else-if="liveupload == 2">
        <input class="inputlivechect" type="text" v-model="networklive" />

        <div class="videolivewebbottom">
          <div>
            <el-button type="primary" size="small" @click="networkplayer"
              >预览视频</el-button
            >
          </div>
          <div>
            <el-button type="primary" size="small" @click="usevideopaly"
              >使用视频</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// qs post连接php
import qs from 'qs';
import { save3dvodm3u8 } from '../../../api/index'
export default {
  data () {
    return {
      playerOptions: {
        //playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "application/x-mpegURL",
          src: "" //你的m3u8地址（必填）
        }],
        poster: "", //你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: '暂无本地视频', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        }
      },
      liveupload: '0',
      // 视频
      liveonetype: '',
      liveonenamelist: '',
      liveoneimgfile: '',
      // 最后地址
      livelist: '',
      dialogVisible: false,
      // 上传时间多少
      timelist: '',
      // 网络地址
      networklive: '',
      // id
      liveid: ''
    }
  },
  methods: {
    playback (e) {
      this.liveupload = e
    },
    editlive () {
      this.$emit("childFn", '000')
      console.log('1')
    },
    // 视频
    // 视频
    beforliveuploadone (file) {
      console.log(file.type)
      const isJPG = file.type === 'video/mp4';
      const isPng = file.type === 'application/x-mpegurlg'
      // const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng) {
        this.$message.error('上传头像视频只能是 mp4 和 m3u8 格式!');
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      return isJPG;
    },
    // 视频1进行
    liveuploadonelisttolive (file) {
      console.log(file.raw.type)
      const isJPG = file.raw.type === 'video/mp4';
      const isPng = file.raw.type === 'application/x-mpegurl'
      // const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG && !isPng) {
        this.$message.error('上传头像视频只能是 mp4 和 m3u8 格式!!');
      } else {
        this.liveonetype = file.raw.type
        this.liveonenamelist = file.name
        // console.log(111)
        this.liveoneimgfile = file.raw
        this.liveuploadoneupload()
        // this.liveone = URL.createObjectURL(file.raw)
      }
    },
    // 视频1上传
    liveuploadoneupload () {
      this.dialogVisible = true
      if (this.liveoneimgfile != '' && this.liveoneimgfile != undefined) {
        console.log(this.liveoneimgfile)
        var COS = require('cos-js-sdk-v5');
        var cos = new COS({
          SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
          SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
        });
        this.cosdata = cos
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let image = this.liveoneimgfile
        let name = this.liveonenamelist
        let namelist = data + '-' + random + '-' + name
        console.log(namelist)
        this.cosdata.putObject({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, /* 必须 */
          StorageClass: 'STANDARD',
          Body: image, // 上传文件对象
          // FilePath: 'tx4s',
          onProgress: (progressData) => {
            console.log(progressData)
            let web = progressData.percent
            console.log(web)
            this.timelist = web * 100 + '%'
            console.log(JSON.stringify(progressData));
            // console.log(progressData)
            if (web === '1') {
              this.$message.error('上传完成');
              this.dialogVisible = false
            }
          }
        }, (err, data) => {
          console.log(err || data);
          console.log(data)
          this.livelist = 'https://' + data.Location
          console.log(this.livelist)
        });
      } else {
        console.log(123)
      }
    },
    // 预览视频
    preview () {
      if (this.livelist == '') {
        this.$message.error('暂无地址');
      } else {
        this.playerOptions.sources[0].src = this.livelist
      }
    },
    // 使用上传视频
    netuploadplayer () {
      if (this.livelist != '') {
        var userid = JSON.parse(localStorage.getItem("userid"));
        let app = qs.stringify({
          txliveid: this.liveid,
          userid: userid,
          cridential: '01',
          thirdm3u8: this.livelist
        })
        save3dvodm3u8(app).then(res => {
          console.log(res)
        })
      }
    },
    // 网络视频
    networkplayer () {
      console.log(this.networklive)
      if (this.networklive == '') {
        this.$message.error('暂无地址');
      } else {
        this.playerOptions.sources[0].src = this.networklive
        this.playerOptions.notSupportedMessage = '视频地址错误'
      }
    },
    // 使用网络视频
    usevideopaly () {
      if (this.networklive != '') {
        var userid = JSON.parse(localStorage.getItem("userid"));
        let app = qs.stringify({
          txliveid: this.liveid,
          userid: userid,
          cridential: '01',
          thirdm3u8: this.networklive
        })
        save3dvodm3u8(app).then(res => {
          console.log(res)
        })
      }
    },
  },
  created () {
    let res = this.$route.query
    this.liveid = res.id
    this.playerOptions.poster = res.img
  }
}
</script>
<style scoped>
.bottomlive {
  margin: 5px 0;
}
/* 视频 */
.videoliveweb {
  width: 357px;
  text-align: center;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 3px;
  margin: 5px 0;
}
.videolivewebbottom {
  width: 357px;
  margin: 5px 0;
  display: flex;
}
.videolivewebbottom div {
  flex: 1;
  text-align: center;
}
/* 字体上传 */
.fontliveupload {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}
/* 文本 */
.inputlivechect {
  width: 334px;
  height: 25px;
  font-size: 16px;
}
</style>
