<template>
  <div>
    <div class="video" v-if="plackupload">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
        @playing="onPlay"
      >
      </video-player>
      <!-- <video width="900" height="400" :src="src"></video> -->
      <div class="choice">
        <div class="title">剪辑1：</div>
        <div
          class="inputlivetime"
          v-for="(item, index) in starttime"
          :key="index"
        >
          <input
            type="text"
            oninput="value=value.replace(/[^\d]/g,'')"
            v-model="item.hour"
          />:
          <input
            type="text"
            oninput="value=value.replace(/[^\d]/g,'')"
            v-model="item.minute"
          />:
          <input
            type="text"
            oninput="value=value.replace(/[^\d]/g,'')"
            v-model="item.second"
          />
          <div class="timelive">开始时间</div>
        </div>
        <div class="bordercreate">
          <div></div>
        </div>
        <div
          class="inputlivetime"
          v-for="(item, index) in endtime"
          :key="index"
        >
          <input
            type="text"
            oninput="value=value.replace(/[^\d]/g,'')"
            v-model="item.hour"
          />:
          <input
            type="text"
            oninput="value=value.replace(/[^\d]/g,'')"
            v-model="item.minute"
          />:
          <input
            type="text"
            oninput="value=value.replace(/[^\d]/g,'')"
            v-model="item.second"
          />
          <div class="timelive">结束时间</div>
        </div>
        <div class="inputlivetime">
          <button style="border: none; background: #fff" @click="preview">
            <input type="text" @click="preview" v-model="serve" disabled />
          </button>
        </div>
      </div>
      <div>
        <el-button type="primary" @click="keepselected">保留选中部分</el-button>
        <el-button type="primary" @click="removeselected"
          >删除选中部分</el-button
        >
        <el-button type="primary" @click="delecteacher">清除设置</el-button>
        <el-button type="primary" @click="uploadplayback">上传回放</el-button>
        <el-button type="primary" @click="suspend" v-if="videolist"
          >暂不回放</el-button
        >
        <el-button type="primary" @click="openpend" v-else>开始回放</el-button>
      </div>
    </div>
    <!-- 回放 -->
    <div class="video" v-else>
      <uploadeventlive @childFn="parentFn"></uploadeventlive>
    </div>
    <!-- 点击预览弹窗 -->
    <div>
      <el-dialog
        v-model="centerDialogVisible"
        title="选择的视频"
        width="30%"
        center
      >
        <div>
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="sonopions"
            @playing="onPlay"
          >
          </video-player>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// 子页面
import uploadeventlive from './components/uploadplayback.vue'
// qs post连接php
import qs from 'qs';
import { getlatestvodm3u8, updatevodm3u8, keepselectedvodm3u8, removeselectedvodm3u8, cleartvodm3u8, stopvod, startvod } from '../../api/index'
// import VueAliplayerv2 from 'vue-aliplayer-v2'
// import 'vue3-video-play/dist/style.css'
// import { videoPlay } from 'vue-video-play'
export default {
  data () {
    return {
      videosrc: [
        { id: 0, src: 'http://alyvod.tongxianglive.cn/videos/akamai/210723/210723nuanchang.m3u8' },
        { id: 1, src: 'http://alyvod.tongxianglive.cn/videos/akamai/210723/210723nuanchang-00007.ts' },
        { id: 2, src: 'https://media.w3.org/2010/05/sintel/trailer.mp4' },
        { id: 3, src: 'http://mirror.aarnet.edu.au/pub/TED-talks/911Mothers_2010W-480p.mp4' },
      ],
      src: '',
      titlesrclive: [],
      // 插件
      playerOptions: {
        //playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "application/x-mpegURL " || 'video/mp4',

          src: "http://alyvod.tongxianglive.cn/videos/akamai/210723/210723nuanchang.m3u8" //你的m3u8地址（必填）
        }],
        poster: "", //你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        }
      },
      serve: '预览',
      starttime: [
        { hour: '00', minute: '00', second: '00' }
      ],
      endtime: [
        { hour: '', minute: '', second: '' }
      ],
      liveid: '',
      //开启关闭
      centerDialogVisible: false,
      // 弹出的视频
      sonopions: {
        //playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "application/x-mpegURL",
          src: "http://alyvod.tongxianglive.cn/videos/akamai/210723/210723nuanchang.m3u8" //你的m3u8地址（必填）
        }],
        poster: "", //你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        }
      },
      // 序号
      version: '',
      // 是否上传回放
      plackupload: true,
      // 是否有回放
      videolist: false
    }
  },
  methods: {
    playlive (e) {
      this.playerOptions.sources[0].src = e
      this.playerOptions.autoplay = true
      console.log(this.playerOptions.sources[0].src)
    },
    // 选择 
    choice (e) {
      // for(){}
      this.titlesrclive.push(e)
    },
    // 
    onPlay (event) {
      console.log('123', event)
    },
    // 点击预览
    preview () {
      // console.log('12')
      var userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: userid,
        cridential: '01',
        version: this.version,
        starthour: this.starttime[0].hour,
        startminute: this.starttime[0].minute,
        startsecond: this.starttime[0].second,
        endhour: this.endtime[0].hour,
        endminute: this.endtime[0].minute,
        endsecond: this.endtime[0].second,
      })
      updatevodm3u8(app).then(res => {
        console.log(res)
        this.sonopions.sources[0].src = res.m3u8
      })
      this.centerDialogVisible = true
      // console.log('12')
    },
    // 页面加载获取视频
    srclive () {
      var userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: userid,
        cridential: '01',
      })
      getlatestvodm3u8(app).then(res => {
        console.log(res)
        if (res.code == '1202') {
          this.playerOptions.notSupportedMessage = res.message
          this.playerOptions.sources[0].src = ''
          this.videolist = false
        } else {
          this.playerOptions.sources[0].src = res.m3u8
          this.endtime[0].hour = res.hour
          this.endtime[0].minute = res.minute
          this.endtime[0].second = res.second
          this.version = res.version
          if (res.defaultvideo == '') {
            this.videolist = false
          } else {
            this.videolist = true
          }
        }
        // console.log(this.endtime)
      })
    },
    // 点击选中保存
    keepselected () {
      var userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: userid,
        cridential: '01',
        version: this.version,
        starthour: this.starttime[0].hour,
        startminute: this.starttime[0].minute,
        startsecond: this.starttime[0].second,
        endhour: this.endtime[0].hour,
        endminute: this.endtime[0].minute,
        endsecond: this.endtime[0].second,
      })
      keepselectedvodm3u8(app).then(res => {
        console.log(res)
        this.playerOptions.sources[0].src = res.m3u8
        this.version = res.version
        this.videolist = true
        res.hour = this.endtime[0].hour
        res.minute = this.endtime[0].minute
        res.second = this.endtime[0].second
        this.starttime = [{ hour: '00', minute: '00', second: '00' }]
      })
    },
    // 删除
    removeselected () {
      var userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: userid,
        cridential: '01',
        version: this.version,
        starthour: this.starttime[0].hour,
        startminute: this.starttime[0].minute,
        startsecond: this.starttime[0].second,
        endhour: this.endtime[0].hour,
        endminute: this.endtime[0].minute,
        endsecond: this.endtime[0].second,
      })
      removeselectedvodm3u8(app).then(res => {
        console.log(res)
        this.playerOptions.sources[0].src = res.m3u8
        this.version = res.version
        res.hour = this.endtime[0].hour
        res.minute = this.endtime[0].minute
        res.second = this.endtime[0].second
        this.starttime = [{ hour: '00', minute: '00', second: '00' }]
      })
    },
    // 上传回放
    uploadplayback () {
      this.plackupload = false
    },
    parentFn (payload) {
      console.log(payload)
      if (payload === '000') {
        this.plackupload = true
      }
    },
    // 清除设置
    delecteacher () {
      var userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: userid,
        cridential: '01',
      })
      cleartvodm3u8(app).then(res => {
        console.log(res)
        if (res.code == '1202') {
          this.playerOptions.notSupportedMessage = res.message
          this.playerOptions.sources[0].src = ''
        } else {
          this.playerOptions.sources[0].src = res.m3u8
          this.endtime[0].hour = res.hour
          this.endtime[0].minute = res.minute
          this.endtime[0].second = res.second
          this.version = res.version
        }
      })
    },
    suspend () {
      var userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: userid,
        cridential: '01',
      })
      stopvod(app).then(res => {
        console.log(res)
        if (res.code == 0) {
          this.$message({
            message: '停止成功',
            type: 'success'
          });
          this.videolist = false
        }
      })
    },
    // 开始回放
    openpend () {
      console.log('开始回放')
      var userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: userid,
        cridential: '01',
      })
      startvod(app).then(res => {
        console.log(res)
        if (res.code == 0) {
          this.$message({
            message: '开启成功',
            type: 'success'
          });
          this.videolist = true
        }
      })
    }
  },
  created () {
    let res = this.$route.query
    this.liveid = res.id
    this.playerOptions.poster = res.img
    this.sonopions.poster = res.img
    this.srclive()
  },
  components: {
    uploadeventlive
  }
}
</script>
<style scoped>
.video {
  width: 960px;
  /* height: 400px; */
  margin: 0 auto;
  /* border: 1px solid #ccc; */
}
.video video {
  border: 1px solid #ccc;
}

.livesrc {
  position: fixed;
  bottom: 20px;
  /* left: 40%; */
  border: 1px solid #ccc;
}
/* 设置 */
.choice {
  display: flex;
  margin: 10px 0;
}
.choice .title {
  font-size: 20px;
  font-size: 800;
  line-height: 42px;
}
.inputlivetime .timelive {
  /* flex: 1; */
  height: 40px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 40px;
}
.inputlivetime input {
  width: 80px;
  height: 42px;
  border-radius: 4px;
  text-align: center;
  background: #ccc;
  color: red;
  font-size: 18px;
  font-weight: 500;
  margin: 0 7px;
}
.bordercreate {
  width: 80px;
}
.bordercreate div {
  /* display: inline-block; */
  /* width: 100%; */
  border-bottom: 5px solid #ccc;
  margin: 25% 0 0 0;
  /* line-height: 38px; */
}
</style>
