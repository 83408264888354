<template>
  <div>
    <!-- 界面 -->
    <div class="wholelive">
      <div v-for="(item, index) in remotecommand" :key="index">
        <input
          v-if="item.commandtype == 1"
          type="text"
          v-model="item.defaultparam"
        />
        <el-button type="primary" size="mini" round @click="weblist(item)">{{
          item.commandname
        }}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus'
import { getremotecommand, remotecommand } from '../../../api/index'
import qs from 'qs'
export default {
  data () {
    return {
      livelistid: '',
      remotecommand: []
    }
  },
  methods: {
    // 页面加载内容
    conster () {
      let app = qs.stringify({
        txliveid: this.livelistid
      })
      getremotecommand(app).then(res => {
        console.log(res.remotecommand)
        this.remotecommand = res.remotecommand
      })
    },
    // 页面点击按钮
    weblist (e) {
      var userid = localStorage.getItem('userid');
      console.log(userid)
      console.log(e)
      if (e.defaultparam == '') {
        let app = qs.stringify({
          txliveid: this.livelistid,
          userid: userid,
          commandid: e.id,
          cridential: '22',
          param: 'none'
        })
        remotecommand(app).then(res => {
          console.log(res)
          if (res.code === '9999') {
            ElMessage({
              showClose: true,
              message: '成功',
              type: 'success',
            })
          } else {
            ElMessage({
              showClose: true,
              message: '失败',
              type: 'success',
            })
          }
        })
      } else {
        let app = qs.stringify({
          txliveid: this.livelistid,
          userid: userid,
          commandid: e.id,
          param: e.defaultparam,
          cridential: '22',
        })
        remotecommand(app).then(res => {
          console.log(res)
          if (res.code === '9999') {
            ElMessage({
              showClose: true,
              message: '成功',
              type: 'success',
            })
          } else {
            ElMessage({
              showClose: true,
              message: '失败',
              type: 'success',
            })
          }
        })
      }
    },
  },
  created () {
    let res = this.$route.query
    console.log(res)
    this.livelistid = res.id
    this.conster()
  }
}
</script>
<style scoped>
.wholelive {
  width: 80%;
  margin: 0 auto;
  border: 1px solid #ccc;
}
</style>
