<template>
  <div>
    <!-- 公众号 -->
    <div>
      <div v-for="(item, index) in weboff" :key="index">
        <div v-if="item.selected == 1">
          <span style="font-weight: 900">当前绑定的公众号:</span>
          {{ item.gongzhonghao }}
        </div>
      </div>
    </div>
    <!-- 单选 -->
    <div>
      <div
        style="
          margin: 16px 0;
          font-weight: 900;
          padding: 5px;
          border: 1px solid #000;
          width: 100px;
          text-align: center;
        "
      >
        进行选择
      </div>
      <el-radio-group
        v-model="radio"
        v-for="(item, index) in weboff"
        :key="index"
      >
        <el-radio :label="item.token" @click="boxlist(item)">{{
          item.gongzhonghao
        }}</el-radio>
      </el-radio-group>
    </div>
    <div style="margin: 10px 0">
      <el-button type="primary" @click="liveing">返回主页面</el-button>
    </div>
  </div>
</template>
<script>
// qs post连接php
import qs from 'qs';
import { gettokenbytxliveid, settokenbytxliveid } from '../../api/index'
export default {

  data () {
    return {
      radio: '',
      weboff: [
      ],
      liveid: ''
    }
  },
  methods: {
    boxlist (e) {
      console.log(e, this.radio)
      var userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: userid,
        cridential: '1',
        token: e.token
      })
      settokenbytxliveid(app).then(res => {
        console.log(res)
        if (res.code == 9999) {
          console.log('成功')
          this.$message({
            message: '更改成功',
            type: 'success'
          });
          this.weboff = res.tokens
        } else {
          console.log('更改失败')
        }

      })

    },
    // 加载获取
    creaofficai () {
      var userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: userid,
        cridential: '1',
      })
      gettokenbytxliveid(app).then(res => {
        console.log(res)
        this.weboff = res.tokens
        for (var i = 0; i < res.tokens.length; i++) {
          if (res.tokens[i].selected == 1) {
            this.radio = res.tokens[i].token
            console.log(this.radio)
          }
        }
      })
    },
    // 返回主页面
    liveing () {
      this.$router.push("/")
    },
  },
  created () {
    let app = this.$route.query
    this.liveid = app.id
    this.creaofficai()
  }
}
</script>
