<template>
  <div>
    <!-- 表格 -->
    <div class="tobleth">
      <!-- 点击下载 -->
      <el-button type="primary" size="small" @click="downloadbao"
        >下载详细数据</el-button
      >
      <el-table :data="tableData" height="550" style="width: 100%">
        <el-table-column label="观众" width="220">
          <template #default="scope">
            <div class="nameabout">
              <div class="imagelist">
                <img :src="scope.row.headimgurl" alt="" />
              </div>
              <div class="namelisttitle">
                <div class="namelive">{{ scope.row.nickname }}</div>
                <div class="nameid">ID:{{ scope.row.id }}</div>
                <!-- <div class="nameid">性别:{{ scope.row.gender }}</div> -->
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="收集信息" width="200">
          <template #default="scope">
            <div class="nameabout">
              <div class="namelisttitle">
                <div class="nameid">姓名：{{ scope.row.nickname }}</div>
                <div class="nameid">手机号码:{{ scope.row.phone }}</div>
                <div class="nameid">手机来源:{{ scope.row.laiyuan }}</div>
              </div>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column label="地区ip" width="200">
          <template #default="scope">
            <div>{{ scope.row.client_ip }}</div>
          </template>
        </el-table-column>
        <el-table-column label="进入时间" width="200">
          <template #default="scope">
            <div>{{ scope.row.entertime }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="退出时间" width="200">
          <template #default="scope">
            <div>{{ scope.row.exittime }}</div>
          </template>
        </el-table-column> -->

        <el-table-column label="终端浏览" width="200">
          <template #default="scope">
            <div v-if="scope.row.client_type == 0" style="text-align: center">
              未知
            </div>
            <div v-if="scope.row.client_type == 1" style="text-align: center">
              微信
            </div>
            <div v-if="scope.row.client_type == 2" style="text-align: center">
              H5
            </div>
            <div v-if="scope.row.client_type == 3" style="text-align: center">
              PC
            </div>
          </template>
        </el-table-column>
        <el-table-column label="直播停留" width="200">
          <template #default="scope">
            <div>{{ scope.row.livewatchtime }}</div>
          </template>
        </el-table-column>
        <el-table-column label="回放停留" width="200">
          <template #default="scope">
            <div>{{ scope.row.vodwatchtime }}</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="220">
          <template #default="scope">
            <el-button type="text" @click="handleClick(scope.row.image)"
              >禁言</el-button
            >
            | <el-button type="text">拉黑</el-button> |
            <el-button type="text">会员卡</el-button>
            |
            <el-button type="text">动态</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          v-model:current-page="currentPage3"
          :page-size="20"
          layout="prev, pager, next, jumper"
          :total="numberweb"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// qs post连接php
import qs from 'qs';
import { getuserwatchreport } from '../../api/index'
export default {
  data () {
    return {
      tableData: [
      ],
      liveid: '',
      // 页数
      numberweb: '',
      pagenumber: 0
    }
  },
  methods: {
    handleClick (e) {
      console.log(e)
    },
    // 获取参数
    parameter () {
      var userid = JSON.parse(localStorage.getItem("userid"));
      // console.log(data1);
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: userid,
        cridential: '01',
        numperpage: 20,
        pagenumber: this.pagenumber
      })
      getuserwatchreport(app).then(res => {
        console.log(res)
        this.tableData = res.data
        this.numberweb = res.totalCount
      })
    },
    // 获取点击数据
    handleCurrentChange (val) {
      console.log('1')
      let pagenumber = ` ${val}` - 1
      console.log(pagenumber)
      var userid = JSON.parse(localStorage.getItem("userid"));
      // console.log(data1);
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: userid,
        cridential: '01',
        numperpage: 20,
        pagenumber: pagenumber
      })
      getuserwatchreport(app).then(res => {
        console.log(res)
        this.tableData = res.data
        this.numberweb = res.totalCount
      })
    },
    // 下载用户详情
    downloadbao () {

      let id = this.liveid
      let a = `https://tx4sdev.tongxianglive.cn/tx4s/getuserwatchreportexcel/video_id/${id}`
      const link = document.createElement('a');
      link.href = a
      // link.download = `${this.liveid}-${this.boxbaoming}`;
      link.click()
    }
  },
  created () {
    // this.main = new Date().valueOf()
    let res = this.$route.query
    this.liveid = res.id
    this.parameter()
  }
}
</script>
<style scoped>
.tobleth {
  /* height: 300px; */
  width: 98%;
  margin: 0 auto;
  background: #fff;
  padding: 10px;
}
/* 观众 */
.nameabout {
  display: flex;
}
/* /头像 */
.imagelist {
  width: 50px;
  margin: 15px 5px 0 0px;
  height: 50px;
}
.imagelist img {
  width: 100%;
  /* height: 100%; */
  border-radius: 25px;
  /* margin-left: px; */
}
/* 姓名 */
.namelisttitle .namelive {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  color: #202124;
  /* text-overflow: ellipsis; */
  overflow: hidden;
}
.namelisttitle .nameid {
  font-size: 14px;
  color: #60646b;
  line-height: 24px;
  height: 24px;
}
</style>
