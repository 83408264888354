<template>
  <div class="admin">
    <div class="adminnav">
      <div class="button">
        <el-button type="primary" size="small" @click="Resetlist"
          >刷新</el-button
        >
        <el-button type="primary" size="small" @click="moreping"
          >更多</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-if="spanopen"
          @click="openlivetong(1)"
          >开启</el-button
        >

        <el-button type="primary" size="small" v-else @click="openlivetong(2)"
          >关闭</el-button
        >
        <span v-if="spanopen == false">现在是开启状态</span>
        <span v-else-if="spanopen == true">现在是关闭状态</span>
      </div>
      <div class="titleconcet">
        <!-- 内容 -->
        <div class="concents">
          <div v-for="(item, index) in concet" :key="index" class="navleave">
            <div class="leave">
              <!-- 头像 -->

              <!-- 内容 -->
              <span class="consleave">
                {{ item.notice }}
                <span>
                  <el-tag @click="addinputlive(item.notice)">加入输入框</el-tag>
                  <el-tag @click="addlistping(item.notice)">使用</el-tag>
                </span>
              </span>
            </div>
          </div>
        </div>
        <!-- 列表 -->
        <div class="listtitle">
          <div class="concentweb">
            <div v-for="(item, index) in listtitleweb" :key="index">
              <span class="consleaves">{{ item.notice }} </span>
              <span>
                <el-tag @click="addwebsocket(item)">使用</el-tag>
              </span>
              <span>
                状态：<span v-if="item.status == 0">不可用</span>
                <span style="color: blue" v-else-if="item.status == 1"
                  >可用</span
                >
                <span style="color: red" v-else-if="item.status == 2"
                  >正在显示</span
                >
              </span>
            </div>
          </div>
          <div class="inputconcent">
            <input
              type="text"
              v-model="inputlist"
              placeholder="请输入内容"
              class="input"
            />
            <button class="bottom" @click="sendlist">发送列表</button>
            <!-- <el-input v-model="input" placeholder="请输入内容"></el-input> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
// 颜色选择器
import { getdefaultnotice, savenotice, getnotice, setnotice, saveandsetnotice, enablenotice, disablenotice, getnoticestatus } from '../../api/index'

export default {
  data () {
    return {
      listweb: 0,
      concet: [],
      inputlist: '',
      listtitleweb: [
        { id: 0, comment_text: '12312312' }
      ],
      liveid: '',
      spanopen: true
    }
  },
  methods: {
    // 加载
    vzanpost () {
      let app = qs.stringify({
        lastid: this.listweb,
        txliveid: this.liveid
      })
      getdefaultnotice(app).then(res => {
        console.log(res)
        this.concet = res.deaultnotices
        this.listweb = this.listweb + 1
        console.log(this.listweb)
      })
    },
    // 更多
    moreping () {
      this.vzanpost()
    },
    // 刷新
    Resetlist () {
      this.listweb = 0
      this.vzanpost()
    },
    // 进入输入框
    addinputlive (e) {
      console.log(e)
      this.inputlist = e
    },
    // 直接使用列表
    addlistping (e) {
      console.log(e)
      let app = qs.stringify({
        txliveid: this.liveid,
        notice: e
      })
      saveandsetnotice(app).then(res => {
        console.log(res)
        if (res.code === '9999') {
          this.$message({
            showClose: true,
            message: '使用成功',
            type: 'success'
          });
          this.getcomment()
        }
      })
    },
    // 发送列表
    sendlist () {
      if (this.inputlist != '') {
        console.log(this.inputlist)
        let app = qs.stringify({
          txliveid: this.liveid,
          notice: this.inputlist
        })
        savenotice(app).then(res => {
          console.log(res)
          if (res.code === '9999') {
            this.inputlist = ''
            this.$message({
              showClose: true,
              message: '发送成功',
              type: 'success'
            });
            // 刷新列表
            this.getcomment()
          }
        })
      } else {
        alert('不能为空')
      }
    },
    // 加载
    getcomment () {
      let app = qs.stringify({
        txliveid: this.liveid
      })
      getnotice(app).then(res => {
        console.log(res)
        this.listtitleweb = res.notices
      })
    },
    // 使用列表
    addwebsocket (e) {
      console.log(e)
      let app = qs.stringify({
        txliveid: this.liveid,
        noticeid: e.id,
        notice: e.notice
      })
      setnotice(app).then(res => {
        console.log(res)
        if (res.code == 9999) {
          this.$message({
            showClose: true,
            message: '使用成功',
            type: 'success'
          });
          this.getcomment()
        }
      })
    },
    // 开启关闭通知
    openlivetong (e) {
      console.log(e)
      if (e == 1) {
        this.spanopen = false
        let app = qs.stringify({
          txliveid: this.liveid
        })
        enablenotice(app).then(res => {
          console.log(res)
        })
      } else if (e == 2) {
        this.spanopen = true
        let app = qs.stringify({
          txliveid: this.liveid
        })
        disablenotice(app).then(res => {
          console.log(res)
        })
      }
    },
    // 查看通知是开启还是关闭
    getnoticestatuslive () {
      let app = qs.stringify({
        txliveid: this.liveid
      })
      getnoticestatus(app).then(res => {
        console.log(res)
        if (res.code === '9999') {
          if (res.noticestatus == 1) {
            this.spanopen = false
          } else {
            this.spanopen = true
          }
        }
      })
    }
  },
  created () {
    let res = this.$route.query
    this.liveid = res.id
    this.getnoticestatuslive()
    this.vzanpost()
    this.getcomment()
  },
  // components: {
  //   vcolorpicker
  // }
}
</script>
<style scoped>
.admin .adminnav .button {
  width: 100%;
  margin: 5px 0;
}
.admin .adminnav .concents {
  width: 50%;
  overflow: scroll;
  border: 1px solid #ccc;
  height: 600px;
}
.admin .adminnav .concents::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
.titleconcet {
  display: flex;
}
.titleconcet .listtitle {
  width: 50%;
  border: 1px solid #ccc;
  height: 600px;
}
.titleconcet .listtitle .concentweb {
  width: 100%;
  height: 550px;
  overflow: scroll;
}
.titleconcet .listtitle .concentweb div {
  /* 内容 */
}
.titleconcet .listtitle .concentweb div .consleaves {
  border: 1px solid #ccc;
  padding: 0 13px;
  line-height: 50px;
  margin-left: 5px;
  font-size: 16px;
}
.titleconcet .listtitle .concentweb div .consleaves span {
  margin: 0 0 0 5px;
}
.titleconcet .listtitle .concentweb::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
.titleconcet .listtitle .inputconcent {
  border-top: 1px solid #ccc;
  height: 50px;
}
.titleconcet .listtitle .inputconcent .input {
  height: 47px;
  border: none;
  border-right: 0.5px solid #ccc;
  padding: 1px 5px;
  outline: none;
  width: 80%;
  font-size: 16px;
}
.titleconcet .listtitle .inputconcent .bottom {
  width: 17%;
  background: #ffff;
  border: none;
  color: #000;
  height: 47px;
  text-align: center;
  padding: 1px 5px;
}
.leavtitle {
  font-size: 16px;
  font-weight: 900;
  margin-left: 5px;
}
.timelive {
  font-size: 14px;
  font-weight: 300;
  color: #ccc;
  margin-left: 16px;
}
/* 头像 */
.leavimg {
  width: 50px;
  height: 50px;
}
.leavimg img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}
/* 内容 */
.consleave {
  flex: 1;
  border: 1px solid #ccc;
  padding: 0 13px;
  line-height: 50px;
  margin-left: 5px;
  font-size: 16px;
  border-radius: 0 10px 10px 10px;
}
.consleave span {
  margin: 0 0 0 5px;
}
.leave {
  display: flex;
}
</style>
