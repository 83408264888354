<template>
  <div>
    <div class="title">直播id为：{{ liveid }}</div>
    <!-- 返回 -->
    <div class="returnlive">
      <div class="examine" v-if="examineapplive == 0">
        <span>已开启留言审核（点击关闭）：</span>
        <el-button size="mini" type="danger" @click="examinelive(1)"
          >关闭</el-button
        >
      </div>
      <div class="examine" v-else-if="examineapplive == 1">
        <span>已关闭留言审核（点击开启）：</span>
        <el-button size="mini" type="primary" @click="examinelive(0)"
          >开启</el-button
        >
      </div>
      <div>
        <span>新留言：({{ newmeagg }})</span>
        <el-button size="small " type="primary" @click="newlistleave"
          >点击刷新</el-button
        >
      </div>
      <div>
        <el-button size="small" type="primary" @click="returnlive"
          >返回主页面</el-button
        >
      </div>
    </div>
    <!-- 留言 -->
    <div class="naveliving">
      <div v-for="item in leave" :key="item.id" class="navleave">
        <div class="leavtitle">
          {{ item.nickname }}:
          <span class="timelive">{{ item.addtime }}</span>
        </div>
        <div class="leave">
          <!-- 头像 -->
          <div class="leavimg"><img :src="item.headimgurl" alt="" /></div>
          <!-- 内容 -->
          <span class="consleave">
            {{ item.comment_text }}
            <span
              ><el-tag type="" effect="dark" @click="approval(item.id, item)"
                >批准</el-tag
              ></span
            >
            <span>
              <el-tag type="danger" @click="refuse(item.id, item)" effect="dark"
                >拒绝</el-tag
              ></span
            >
            <span>
              <i
                v-if="item.approve_status == 1"
                class="el-icon-circle-check"
              ></i>
              <i
                v-else-if="item.approve_status == 0"
                class="el-icon-circle-close"
              ></i>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="number">
      <el-pagination
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="total"
        v-model:current-page="currentPage1"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getcommentsbypage, setserverwsid, setcommentautoapprove, approvecommenttest, rejectcommenttest } from '../../api/index'
import qs from 'qs';
export default {
  data () {
    return {
      // id
      liveid: '',
      liveleaving: '',
      // 报名数据
      leave: '',
      // 页数的条数
      total: '',
      // 进行了什么操作
      examineapplive: '',
      // 页数
      currentPage1: 1,
      // 新消息
      newmeagg: 0,
      // 
      timerlistlive: '',
      // 判断是否已经断开
      isConnect: false
    }
  },
  methods: {
    // 页面加载操作
    leaving () {
      let app = qs.stringify({
        video_id: this.liveid,
        numperpage: 20,
        pagenumber: 0
      })
      getcommentsbypage(app).then(res => {
        console.log(res)
        this.leave = res.comments
        this.total = res.totalCount / 2
        this.examineapplive = res.autoapprove
        // this.total = 100
      })
    },
    // 点击是否审核
    examinelive (e) {
      console.log(e)
      if (e == 1) {
        let app = qs.stringify({
          approve_status: e,
          video_id: this.liveid
        })
        setcommentautoapprove(app).then(res => {
          console.log(res)
          if (res.code == 9999) {
            this.examineapplive = 1
            this.$message({
              message: '关闭成功',
              type: 'success'
            });
          } else {
            this.$message.error('失败');
          }
        })
      } else if (e == 0) {
        let app = qs.stringify({
          approve_status: e,
          video_id: this.liveid
        })
        setcommentautoapprove(app).then(res => {
          console.log(res)
          if (res.code == 9999) {
            this.examineapplive = 0
            this.$message({
              message: '开启成功',
              type: 'success'
            });
          } else {
            this.$message.error('失败');
          }
        })
      }

    },
    // 点击页数跳转数据
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      let id = ` ${val}` - 1
      console.log(id)
      let app = qs.stringify({
        video_id: this.liveid,
        numperpage: 20,
        pagenumber: id
      })
      getcommentsbypage(app).then(res => {
        console.log(res)
        this.leave = res.comments
        this.total = res.totalCount / 2
        // this.total = 100
      })
    },
    // 点击批准
    approval (e, a) {
      if (a.approve_status == 0) {
        let app = qs.stringify({
          comment_id: e,
          video_id: this.liveid,
        })
        approvecommenttest(app).then(res => {
          console.log(res)
          if (res.code === '9999') {
            this.$message({
              message: '批准成功',
              type: 'success'
            });
            a.approve_status = 1
          } else {
            this.$message.error('失败');
          }
        })
      } else {
        this.$message({
          message: '已经批准',
          type: 'warning'
        });
      }
      // console.log(a.approve_status)

    },
    // 点击拒绝
    refuse (e, a) {
      if (a.approve_status == 1) {
        console.log(e, a)
        let app = qs.stringify({
          comment_id: e,
          video_id: this.liveid
        })
        rejectcommenttest(app).then(res => {
          console.log(res)
          if (res.code === '9999') {
            this.$message({
              message: '拒绝成功',
              type: 'success'
            });
            a.approve_status = 0
          } else {
            this.$message.error('失败');
          }
        })
      } else {
        this.$message({
          message: '已经拒绝',
          type: 'warning'
        });
      }
    },
    // 刷新
    returnlive () {
      this.$router.go(-1)
    },
    // /点击刷新
    newlistleave () {
      this.leaving()
      this.currentPage1 = 1
      this.newmeagg = 0
    },
    // wesoter数据传输
    initWebSocket () {
      //初始化weosocket
      const wsuri = "wss://tx4ws.tongxianglive.cn";
      console.log(wsuri);
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen () {
      let that = this;
      that.isConnect = true
      that.timerlistlive = setInterval(function () {
        // let a = 1; //这样就可以直接操作data里边的值了
        that.websocketsend(1);
      }, 25000);
      console.log(111);
    },
    websocketonerror () {
      //连接建立失败重连
      // this.initWebSocket();
      this.isConnect = false
      this.reConnect()
      // return
      // console.log("失败");
    },
    websocketonmessage (e) {
      console.log(e.data)
      let el = eval("(" + e.data + ")");
      if (el.type === "init") {
        let apps = el.client_id
        console.log(apps, this.liveid)
        console.log(1)
        let app = qs.stringify({
          client_id: apps,
          video_id: this.liveid,
        })
        setserverwsid(app).then(res => {
          console.log(res)
        })
      } else if (el.type === "newmsg") {
        // console.log()
        this.newmeagg = this.newmeagg + 1
        console.log(this.newmeagg)
      }
      console.log(el);
      //数据接收
      // const redata = JSON.parse(e.data);
    },
    websocketsend (data) {
      //数据发送
      console.log(1);
      if (data != 1) {
        this.websock.send(data);
        console.log(2);
      } else if (data == 1) {
        console.log(1);
        this.websock.send(1);
      }
    },
    websocketclose (e) {
      //关闭
      this.isConnect = false
      clearInterval(this.timerlistlive);
      console.log("断开连接", e);
      this.reConnect();
      // return
    },
    // 断开连接超时重新
    reConnect () {
      let that = this;
      console.log('尝试重新连接');
      //如果已经连上就不在重连了	
      if (that.isConnect) {
        return;
      }
      clearTimeout(that.rec);
      // 延迟5秒重连  避免过多次过频繁请求重连	
      that.rec = setTimeout(function () {
        that.initWebSocket();
      }, 5000);
    }
  },
  created () {
    let app = this.$route.query
    this.liveid = app.liveid
    this.leaving()
    this.initWebSocket()
    // this.handleCurrentChange
  }
}
</script>
<style scoped>
/* 标题 */
.title {
  text-align: center;
  color: #000;
  font-size: 20px;
  font-weight: 900;
}
/* 返回a */
.returnlive {
  /* text-align: right; */
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding: 0 0 5px 0;
}
/* 评论 */
.naveliving {
  width: 90%;
  height: 500px;
  margin: 0 auto;
  border: 1px solid #ccc;
  overflow: scroll;
  /* scrollbar-width: none; */
}
.naveliving::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
/* 评论内容 */
.navleave {
  padding: 10px;
}
.leave {
  display: flex;
}
/* 名字 */
.leavtitle {
  font-size: 16px;
  font-weight: 900;
  margin-left: 5px;
}
.timelive {
  font-size: 14px;
  font-weight: 300;
  color: #ccc;

  margin-left: 16px;
}
/* 头像 */
.leavimg {
  width: 50px;
  height: 50px;
}
.leavimg img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}
/* 内容 */
.consleave {
  border: 1px solid #ccc;
  padding: 0 13px;
  line-height: 50px;
  margin-left: 5px;
  font-size: 16px;
  border-radius: 0 10px 10px 10px;
}
.consleave span {
  margin: 0 0 0 5px;
}
/* 分页数 */
.number {
  padding: 10px 0;
  margin: 0 auto;
  text-align: center;
  width: 90%;
  background: #ccc;
  margin-top: 10px;
}
</style>
